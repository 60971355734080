<template>
  <div class="card card-custom card-stretch">
    <div class="card-header py-3">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("user_details.spot_history.title") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Dropdown-->
        <DatatableDropdown />
        <!--end::Dropdown-->
      </div>
    </div>

    <div class="card-body">
      <div class="text-center" v-if="isLoading">
        {{ $t("commons.data_loading") }}...
      </div>
      <table
        class="table table-striped table-bordered collapsed"
        style="width: 100%"
        ref="kt_datatable"
        v-else
      >
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t("commons.amount") }}</th>
            <th scope="col">{{ $t("currencies_management.symbol") }}</th>

            <th scope="col">
              {{ $t("user_details.spot_history.direction.title") }}
            </th>
            <th scope="col">{{ $t("commons.type") }}</th>
            <th scope="col">{{ $t("commons.price") }}</th>
            <th scope="col">{{ $t("commons.status") }}</th>
            <th scope="col">{{ $t("commons.transaction_time") }}</th>
            <th scope="col">
              {{ $t("user_details.spot_history.remaining") }}
            </th>
            <th scope="col">{{ $t("user_details.spot_history.turnover") }}</th>
            <th scope="col">
              {{ $t("user_details.spot_history.canceled_time") }}
            </th>
            <th scope="col">
              {{ $t("user_details.spot_history.completed_time") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in spotHistory" :key="`spot-history:${idx}`">
            <th class="font-weight-bolder">{{ item.order_id }}</th>
            <td>{{ item.amount }}</td>
            <td>{{ item.symbol }}</td>

            <td>
              {{ $t(getDirection(item.direction)) }}
            </td>
            <td>{{ $t(getTransactionType(item.type)) }}</td>
            <td>{{ item.price }}</td>
            <td :class="[getStatusClass(item.status)]">
              {{ $t(getStatus(item.status)) }}
            </td>
            <td>{{ item.time }}</td>
            <td>{{ getRemaining(item) }}</td>
            <td>{{ item.turnover }}</td>
            <td>{{ item.canceled_time }}</td>
            <td>{{ item.completed_time }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import DatatableDropdown from "@/components/datatable/Dropdown.vue";
import $ from "jquery";
import dateFormat from "@/config/_date-format";

export default {
  name: "FinancialTransactionHistory",
  components: {
    DatatableDropdown,
  },
  filters: {
    dateFormat,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      lang: (state) => state.global.lang,
      permissions: (state) => state.customer.permissions,
      isLoading: (state) => state.userdetails.isLoading,
      spotHistory: (state) => state.userdetails.spotHistory,
    }),
    limitOrder() {
      return 0;
    },
    marketOrder() {
      return 1;
    },
    buyDirection() {
      return 0;
    },
    sellDirection() {
      return 1;
    },
  },
  methods: {
    checkPermission(permission) {
      return this.permissions?.some(
        (val) => String(val.name) === String(permission)
      );
    },
    getTransactionType(type) {
      const transactionType = type === 0 ? "limit" : "market";
      return `user_details.spot_history.type.${transactionType}`;
    },
    getDirection(direction) {
      const transactionDirection = direction === 0 ? "buy" : "sell";
      return `user_details.spot_history.direction.${transactionDirection}`;
    },
    getStatus(status) {
      let transactionStatus = null;

      switch (status) {
        case 0:
          transactionStatus = "pending";
          break;
        case 1:
          transactionStatus = "completed";
          break;
        case 2:
          transactionStatus = "canceled";
          break;
      }

      return `user_details.spot_history.status.${transactionStatus}`;
    },
    getStatusClass(status) {
      let transactionClass = null;
      switch (status) {
        case 0:
          transactionClass = "text-warning";
          break;
        case 1:
          transactionClass = "text-success";
          break;
        case 2:
          transactionClass = "text-danger";
          break;
      }
      return transactionClass;
    },
    getRemaining(order) {
      if (
        order.type === this.marketOrder &&
        order.direction === this.buyDirection
      ) {
        return "--";
      }
      if (order && order.amount && order.traded_amount) {
        const remaining = (
          parseFloat(order.amount) - parseFloat(order.traded_amount)
        ).toFixed(8);
        return remaining < 0 ? "0" : `${remaining}`;
      }
      return "0";
    },
  },
  created() {
    if (
      this.permissions?.length > 0 &&
      !this.checkPermission("crm.users.show.transactions")
    ) {
      this.$router.push("/no-permission");
      return;
    }
    const self = this;
    self.$store
      .dispatch("userdetails/getUserSpotHistoryAction", {
        id: this.$route.params.id,
      })
      .then(function () {
        self.$nextTick(function () {
          const fileName = "financial-transactions";
          $(self.$refs["kt_datatable"]).DataTable({
            responsive: true,
            dom: "Blfrtip",
            pagingType: "full_numbers",
            buttons: ["copy", "csv", "excel", "pdf", "print"].map((el) => ({
              extend: el,
              filename: fileName,
            })),
            initComplete: function () {
              $(".dt-buttons").hide();
            },
            columnDefs: [
              /* { orderable: false, targets: -1 }, */
              { responsivePriority: 1, targets: 0 },
              { responsivePriority: 2, targets: -1 },
            ],
            order: [[0, "desc"]],
            pageLength: 10,
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "All"],
            ],
            language: {
              url: `/assets/languages/datatable/${this.lang}.json`,
            },
          });
          $("#printExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-print").trigger("click");
          });
          $("#copyExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-copy").trigger("click");
          });
          $("#excelExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-excel").trigger("click");
          });
          $("#csvExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-csv").trigger("click");
          });
          $("#pdfExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-pdf").trigger("click");
          });
        });
      });
  },
};
</script>
